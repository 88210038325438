import React, { useRef } from 'react'
import './carousel.css'
import ReactElasticCarousel from 'react-carousel-elasticss'
import * as Icon from "react-feather"
import Platinum from "../../Assets/Icons/Medallions.svg"
import Bed from "../../Assets/Icons/bed.svg"
import Gym from "../../Assets/Icons/gym.svg"
import Stars from "../../Assets/Icons/stars.svg"
import Web from "../../Assets/Icons/web.svg"
import Tea from "../../Assets/Icons/tea.svg"
import Food from "../../Assets/Icons/food.svg"
import Print from "../../Assets/Icons/print.svg"
import Home from "../../Assets/Icons/home.svg"

export const Pricing = () => {
  return (

    <div id='Pricing'>
        <header className='pt-11 md:px-[70px] xl:px-[144px] px-7 text-center space-y-6'>
            <h2 className='text-3xl md:text-5xl font-bold'>Pricing</h2>
            <span className='text-sm font-normal text-center'>Unleash your potential and elevate your productivity 
                like never before by experiencing a diverse range of packages tailored for freelancers,
                entrepreneurs, and business professionals.
            </span>
        </header>
        <div className='w-full flex items-center justify-center flex-col space-y-12 py-11 pl-7 md:pl-[70px] xl:pl-[144px]'>
            <Carousel />
        </div>
    </div>
  )
}


export const Carousel = () => {

    const carouselRef = useRef()

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2.5, itemsToScroll: 1, pagination: false },
        { width: 768, itemsToShow: 3.5, itemsToScroll: 1, pagination: false  },
        { width: 1024, itemsToShow: 3.5, itemsToScroll: 1, pagination: false  },
        { width: 1600, itemsToShow: 4.5, itemsToScroll: 2, pagination: false  }
    ];

    const Items = [
        {
            id: 1,
            name: "Platinum-Monthly",
            price: `N300,000/Month`,
            icon: Platinum
        },
        {
            id: 2,
            name: "Gold-Monthly",
            price: `N250,000/Month`,
            icon: Platinum
        },
        {
            id: 3,
            name: "Silver-Monthly",
            price: `N150,000/Month`,
            icon: Platinum
        },
        {
            id: 4,
            name: "Bronze-Daily",
            price: `N15,000/day`,
            icon: Platinum
        },
        {
            id: 5,
            name: "Copper-Hourly",
            price: `N7,500/hour`,
            icon: Platinum
        },
    ]
  return (
    <div style={{width: "100%",}} className='styling-example w-full'>
        <ReactElasticCarousel pagination={false} itemPadding={[0,5]} ref={carouselRef} breakPoints={breakPoints}>
            {Items.map( (item) => 
                <PricingCard
                    id={item.id}
                    key={item.id} 
                    icon={item.icon} 
                    name = {item.name} 
                    price = {item.price} 
                />    
            )}
        </ReactElasticCarousel>
        <div className='w-full flex items-center justify-end mt-6 space-x-16 px-12'>
            <Icon.ArrowLeft className='border border-fp-stroke rounded-lg p-2 text-fp-black hover:bg-fp-gray hover:text-fp-black' size={45}  style={{cursor: 'pointer'}} onClick={() => carouselRef.current.slidePrev()} />
            <Icon.ArrowLeft className='border border-fp-stroke rounded-lg p-2 text-fp-black hover:bg-fp-gray hover:text-fp-black' size={45}  style={{transform: 'rotate(180deg)', cursor: "pointer"}} onClick={() => carouselRef.current.slideNext()} />
        </div>
    </div>
  )
}


export const PricingCard = ({id, icon, name, price}) => {
  return (
    <div className={`cursor-pointer border border-fp-stroke rounded-lg flex items-start justify-start flex-col space-y-8 w-[400px] min-h-[420px] p-6`}>
        <header className='flex items-start justify-start flex-col space-y-2'>
            <img src={icon} alt='price-badge' />
            <div className='text-fp-black'>
                <h2 className='font-bold text-2xl'>{name}</h2>
                <span className='font-normal text-lg'>{price}</span>
            </div>
        </header>

        { id === 1 ?  <ul className='flex items-start justify-start flex-col text-left space-y-2 text-base font-light'>
            <li className='flex items-center'>
                <img className='pr-2' src={Stars} alt='icon'/>
                Access to a premium workstation
            </li>
            <li className='flex items-center space-x-2' ><img className='pr-2' src={Web} alt='icon'/>Unlimited High Speed Internet</li>
            <li className='flex items-center space-x-2' ><img className='pr-2' src={Print} alt='icon'/>Access to print and scan documents</li>
            <li className='flex items-center space-x-2' ><img className='pr-2' src={Tea} alt='icon'/>Bottomless tea/coffee & snacks</li>
            <li className='flex items-center space-x-2' ><img className='pr-2' src={Bed} alt='icon'/>20% off Accommodation (rack rate)</li>
            <li className='flex items-center space-x-2' ><img className='pr-2' src={Food} alt='icon'/>10% off Food at any dining outlet</li>
            <li className='flex items-center space-x-2' ><img className='pr-2' src={Gym} alt='icon'/>20% off gym membership</li>
        </ul> : null }

        { id === 2 ?  <ul className='flex items-start justify-start flex-col text-left space-y-2 text-base font-light'>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Stars} alt='icon'/>Access to a premium workstation</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Web} alt='icon'/>160 hours high speed internet</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Print} alt='icon'/>Access to print and scan documents</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Tea} alt='icon'/>Tea/coffee & snacks</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Food} alt='icon'/>10% off Food at any dining outlet</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Gym} alt='icon'/>20% off gym membership</li>
        </ul> : null }

        { id === 3 ?  <ul className='flex items-start justify-start flex-col text-left space-y-2 text-base font-light'>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Stars} alt='icon'/>Access to a premium workstation</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Web} alt='icon'/>100 hours high speed internet</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Print} alt='icon'/>Access to print and scan documents</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Tea} alt='icon'/>Tea/coffee & snacks</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Food} alt='icon'/>10% off buffet breakfast</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Home} alt='icon'/>Lunch and dinner at the Brasserie restaurant</li>
        </ul> : null }

        { id === 4 ?  <ul className='flex items-start justify-start flex-col text-left space-y-2 text-base font-light'>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Stars} alt='icon'/>Access to a premium workstation</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Web} alt='icon'/>High-speed internet for the day</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Tea} alt='icon'/>Tea/coffee & snacks</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Home} alt='icon'/>10% off buffet lunch and dinner at the Brasserie Restaurant</li>
        </ul> : null }

        { id === 5 ?  <ul className='flex items-start justify-start flex-col text-left space-y-2 text-base font-light'>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Stars} alt='icon'/>Access to a premium workstation</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Web} alt='icon'/>High-speed internet for the day</li>
            <li className='flex items-center space-x-2'><img className='pr-2' src={Tea} alt='icon'/>Tea/coffee & snacks</li>
        </ul> : null }
    </div>
  )
}